<!--java class 反编译-->
<template>
  <div class="md5-javaClass-page">
    <md5Header />
    <div class="md5-javaClass-box">
      <div class="javaClass-component">
        <div class="head-bar">
          <a
            href="javascript:history.back(-1)"
            rel="noopener noreferrer"
            class="goback"
            :title="$t('lang.gobackBack')"
          >
            <i class="el-icon-back"></i>
            {{ $t("lang.goback") }}
          </a>
          <h3 class="title">{{ h3 }}</h3>
        </div>
        <div class="javaClass-step-box">
          <!-- 状态 wait / process / finish / error / success -->
          <el-steps
            :active="auditStep"
            finish-status="success"
            :process-status="StepProcessStatus"
            align-center
          >
            <el-step title="上传java class文件"></el-step>
            <el-step title="下载结果"></el-step>
          </el-steps>
        </div>
        <!-- 审计操作区域 -->
        <div class="javaClass-audit-box">
          <el-card shadow="always">
            <!-- 上传文件 -->
            <div class="upload-file-step" v-show="auditStep == 0">
              <el-upload
                drag
                action="/api/v1/file/upload-class-file"
                multiple
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :show-file-list="false"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  {{ $t("lang.websocket.uploadText") }}
                  <em>{{ $t("lang.websocket.clickUpload") }}</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  {{ $t("lang.websocket.uploadTip") }}
                </div>
              </el-upload>
              <div class="foot">
                <el-alert
                  title="工具仅支持上传java class包"
                  type="info"
                  show-icon
                  center
                  class="foot-info"
                ></el-alert>
              </div>
            </div>
            <!-- 反编译结果下载 -->
            <div class="javaclass-result" v-show="auditStep == 1">
              <el-result
                icon="success"
                title="反编译成功"
                subTitle="请根据提示进行操作"
              >
                <template slot="extra">
                  <el-popconfirm
                    title="您确定要返回重新上传吗？"
                    @confirm="auditStep = 0"
                  >
                    <el-button type="primary" size="medium" slot="reference"
                      >重新上传</el-button
                    >
                  </el-popconfirm>
                  <el-button
                    type="success"
                    icon="el-icon-download"
                    size="medium"
                    style="margin-left: 10px"
                    @click="downloadFile"
                    >下载文件</el-button
                  >
                </template>
              </el-result>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <md5Footer />
  </div>
</template>

<script>
import md5Header from "./../../components/Header";
import md5Footer from "./../../components/Footer";
import { get_tools_detail } from "@/api/tools";

export default {
  name: "java-class-decompilation",
  components: {
    md5Header,
    md5Footer,
  },
  data() {
    return {
      auditStep: 0,
      StepProcessStatus: "", //success  error
      resultData: {},
      h3: "",
    };
  },
  created() {
    this.getToolsDetail();
  },
  methods: {
    getToolsDetail() {
      get_tools_detail({ id: this.$route.query.id }).then((res) => {
        if (res) {
          this.h3 = res.name;
        }
      });
    },
    //上传成功
    uploadSuccess(res, file) {
      console.log(res, file);
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: "success",
        });
        this.auditStep = 1;
        this.resultData = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    //上传失败
    uploadError(err) {
      this.$message({
        message: "文件上传失败:" + err,
        type: "error",
      });
    },
    //下载
    downloadFile() {
      this.auditStep = 0;
      window.open(this.resultData[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.md5-javaClass-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url(../../assets/img/cmd-index-bg.png) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  .md5-javaClass-box {
    flex: 1;
    padding-top: 30px;
    width: 1208px;
    margin: 0 auto;
  }
  .javaClass-component {
    width: 100%;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 3px 26px 3px #2318151a;
    padding: 35px;
    border-radius: 6px;

    .title {
      width: 100%;
      height: 43px;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
      line-height: 36px;
      text-align: center;
    }
    .goback {
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: rgba(68, 169, 254, 1);
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        font-size: 18px;
      }
      &:hover {
        color: #37bef0;
      }
    }

    .javaClass-step-box {
      margin-top: 15px;
    }

    .javaClass-audit-box {
      width: 830px;
      min-height: 300px;
      margin: 30px auto 0;
      text-align: center;

      /deep/ .el-card__body {
        padding: 60px 20px;
      }
      /deep/ .el-upload-dragger {
        width: 400px;
        height: 200px;
      }
      .foot {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 35px;
      }
      .foot-info {
        width: 40%;
      }
    }
  }
}
</style>
